<template>
  <v-row>
    <v-col md="12">
      <v-card>
        <v-card-title>
          <!--<v-icon
            class="mr-4"
            style="cursor: pointer"
            title="voltar"
            @click="pushRouteToView('clients')"
          >
            mdi-arrow-left
          </v-icon>-->
          <v-btn small  @click="pushRouteToView('clients')" class="mr-4 btn-back" >
                            <v-icon >
                        mdi-arrow-left
                             </v-icon>
                              Voltar
                            </v-btn>
          <v-icon>mdi-store</v-icon>
          Dados do Client
          <v-spacer></v-spacer>
          <v-btn outlined class="btn-del"
                 @click="dialogDeleteClient=true">
            <v-icon left>mdi-delete</v-icon>
            Excluir
          </v-btn>

          <v-btn outlined
                 :class="`${client.active? 'btn-inactive': 'btn-active'} mx-2`"
                 @click="dialogActiveInactive=true">
            <span v-if="client.active"><v-icon left>mdi-diameter-variant</v-icon>INATIVAR NO APLICATIVO</span><span v-else><v-icon
            left>mdi-checkbox-marked-circle-outline</v-icon>ATIVAR NO APLICATIVO</span>
          </v-btn>
          <v-btn
            color="#3A33E8"
            dark
            :to="{ name: 'clientEdit', params: client.id }"
          >
            <v-icon left>mdi-pencil</v-icon>
            Editar
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-data-visualization">
          <v-row class="pt-3">
            <v-col md="12" sm="6">
              <b><v-icon>mdi-card-account-details-outline</v-icon>  CNPJ: </b> {{ client.cnpj }}
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6" sm="6">
              <b><v-icon>mdi-office-building</v-icon> Razão Social:</b> {{ client.companyName }}
            </v-col>
            <v-col md="6" sm="6">
              <b><v-icon>mdi-office-building</v-icon> Nome Fantasia:</b> {{ client.fantasyName }}
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6" sm="6">
              <b><v-icon>mdi-at</v-icon> Email:</b> {{ client.email }}
            </v-col>
            <v-col md="6" sm="6">
              <b><v-icon>mdi-phone</v-icon> Telefone:</b> {{ client.phone }}
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" sm="12">
              <b><v-icon>mdi-map-marker</v-icon> CEP:</b>
              {{ client.zipcode }}
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" sm="12">
              <b><v-icon>mdi-map-marker</v-icon> Endereço:</b>
              {{ client.street }}, {{ client.number }}
            </v-col>

          </v-row>
          <v-row>
            <v-col md="6" sm="12">
              <b><v-icon>mdi-map-marker</v-icon> Complemento</b>
              {{ client.addressComplement }}
            </v-col>
            <v-col md="6" sm="12">
              <b><v-icon>mdi-map-marker</v-icon> Bairro:</b>
              {{ client.district }}
            </v-col>
          </v-row>
          <v-row>

            <v-col md="6" sm="12">
              <b><v-icon>mdi-map-marker</v-icon> Cidade:</b>
              {{ client.city }}
            </v-col>
            <v-col md="6" sm="12">
              <b><v-icon>mdi-map-marker</v-icon> Estado:</b>
              {{ client.state }}
            </v-col>
          </v-row>
          <!--
          <v-row>
            <v-col md="12" sm="12">
              <b>Descrição:</b>
              {{ client.desc }}
            </v-col>
          </v-row>
          -->
          <v-row>
            <v-col sm="12" class="text-right">
              <v-btn small outlined>
                <v-icon>
                  mdi-lock-outline
                </v-icon>
                Trocar senha do master
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <confirm-dialog
          :dialog="dialogActiveInactive"
          :title="`${client.active? 'INATIVAR ': 'ATIVAR '} ${client.companyName}`"
          body="Tem certeza que deseja realizar essa ação?"
          @dialog-event="inactiveActive">
        </confirm-dialog>
        <confirm-dialog
          :dialog="dialogDeleteClient"
          :title="`EXCLUIR  ${client.companyName}`"
          :body="`Tem certeza que deseja excluir o serviço ${client.companyName} ?`"
          @dialog-event="deleteClient">
        </confirm-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {CLIENT_ACTIVE, CLIENT_DELETE, CLIENT_GET, CLIENT_INACTIVE} from "@/store/actions/client.type";
import {CLIENT_RESET, SET_CLIENT_ACTIVE} from "@/store/mutations/client.type";
import {CHANGE_PROGRESS} from "@/store/mutations/mutations.type";
import ConfirmDialog from '@/components/ConfirmDialog'

export default {
  name: "clientId",

  props: {
    id: {
      default: 0,
      type: Number,
      required: true
    }
  },
  components: {ConfirmDialog},
  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.dispatch(CLIENT_RESET);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([
      await store.commit(CLIENT_RESET),
      await store.dispatch(CLIENT_GET, to.params.id)
    ]).then(() => {
      next();
    });
  },
  data: () => ({
    dialogActiveInactive: false,
    dialogDeleteClient: false
  }),
  methods: {
    pushRouteToView(route) {
      this.$router.push({name: route});
    },
    deleteClient(status) {
      this.dialogDeleteClient = false
      if (!status) {
        return
      }
      this.$store.commit(CHANGE_PROGRESS, true)
      this.$store
        .dispatch(CLIENT_DELETE)
        .then(() => {

          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.color = "success";
          this.snackbar.text = "Excluído com sucesso";

          this.$router.push({
            name: "clients",
          });
        })
        .catch(({response}) => {
          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.color = "error";
          this.snackbar.text = "Erro ao excluir.";
          // console.log(response.data);
          if (typeof response.data.message !== "undefined") {
            this.snackbar.text = response.data.message;
          }
        });
    },
    inactiveActive(status) {
      this.dialogActiveInactive = false
      if (!status) {
        return
      }
      let action = this.client.active ? CLIENT_INACTIVE : CLIENT_ACTIVE;
      
      this.$store.commit(CHANGE_PROGRESS, true)

      this.$store
        .dispatch(action, this.client.id)
        .then(() => {

          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.color = "success";
          this.snackbar.text = "Alterado com sucesso";
          this.$store.commit(SET_CLIENT_ACTIVE, !this.client.active)
        })
        .catch(({response}) => {
          this.$store.commit(CHANGE_PROGRESS, false)
          this.snackbar.snackbar = true;
          this.snackbar.color = "error";
          this.snackbar.text = "Erro ao alterar.";
          // console.log(response.data);
          if (typeof response.data.message !== "undefined") {
            this.snackbar.text = response.data.message;
          }
        });
    }
  },

  computed: {
    ...mapGetters(["snackbar","checkUsers", "client"])
  }
};
</script>
